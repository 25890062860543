import { useState, useEffect } from "react";
import UpdateVast from "../UpdateVast/UpdateVast";
import ManageVasts from "../ManageVasts/ManageVasts";
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

const Vasts = (props) => {

    const [id, setId] = useState(props.id);
    const [vasts, setVasts] = useState([]);
    const [vastId, setVastId] = useState('');
    const [open, setOpen] = useState(false);
    const [showUpdateVast, setShowUpdateVast] = useState(false);
    const [showAddVast, setShowAddVast] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    useEffect(() => {
        (async () => {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/get/vasts/${id}`, {
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            });
            if (res.status === 403) {
                navigate("/");
            }
            const data = await res.json();
            if (data) {
                setVasts(data);
                setLoading(false);
            }

        }
        )()

    }, [open, setVasts, id, navigate, setLoading])


    const addVast = (e) => {
        setShowUpdateVast(false);
        setShowAddVast(true);
        setOpen(true);

    }

    const backToLayOut = () => {
        props.onClose();
    }

    const updateVast = (e) => {

        setShowAddVast(false);
        setShowUpdateVast(true);
        setVastId(e.target.id);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);

    };


    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, }}
                open={true}
            >
                {loading ? <CircularProgress /> :
                    <Card sx={{ minWidth: 1400, maxHeight: 700, borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                        <CardHeader
                            action={
                                <IconButton sx={{ position: 'relative', left: '670%' }} onClick={backToLayOut}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            title="Vasts"
                            sx={{ color: '#289FD2', display: 'flex' }}

                        />
                        <CardContent>


                            <TableContainer component={Paper} sx={{ overflowY: 'scroll', maxHeight: '500px' }}>
                                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                    <TableHead sx={{ background: '#CEE7F2' }} >
                                        <TableRow sx={{ fontFamily: "'Inter', 'sans-serif'", fontStyle: 'normal', fontWeight: '500', color: '#8695A0' }}>
                                            <TableCell >Vast ID</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">Url</TableCell>
                                            <TableCell align="center">Max Retry</TableCell>
                                            <TableCell align="center">Priority</TableCell>
                                            <TableCell ></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {vasts.map((vast) => (
                                            <TableRow
                                                key={vast.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">{vast.id}</TableCell>
                                                <TableCell align="center">{vast.tag_name}</TableCell>
                                                <TableCell align="center">{vast.vast_url}</TableCell>
                                                <TableCell align="center">{vast.max_retry}</TableCell>
                                                <TableCell align="center">{vast.priority}</TableCell>
                                                <TableCell align="center"><button onClick={updateVast} id={vast.id}>Update Vast</button></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                        <CardActions>
                            <button style={{ width: '118px', height: '36.31px', fontFamily: 'Inter', fontWeight: 600 }} onClick={addVast}> add Vast</button>
                        </CardActions>
                    </Card>
                }
            </Backdrop >
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{ style: { background: "rgba(0, 0, 0, 0.15)" } }}
            >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    {showUpdateVast &&
                        <UpdateVast
                            id={vastId}
                            onClose={handleClose}
                        />
                    }
                    {showAddVast &&
                        <ManageVasts
                            id={id}
                            onClose={handleClose}
                        />

                    }
                </DialogContent>
            </Dialog>
        </div >

    )

}

export default Vasts