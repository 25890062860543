import { useState, useEffect } from "react";
import styles from './Demand.module.css'
import DemandVasts from "../DemandVasts/DemandVasts";
import NewDemand from "../NewDemand/NewDemand";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';


const Demand = () => {

    const navigate = useNavigate();
    const [demand, setDemand] = useState([]);
    const [id, setId] = useState([]);
    const [demandVast, setDemandVast] = useState(false);
    const [newDemand, setNewDemand] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        (async () => {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/demand`, {
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            });
            if (res.status === 403) {
                navigate("/");
            }
            const data = await res.json();
            if (data) {
                setLoading(false);
                setDemand(data);
            }
        }
        )()

    }, [open])

    const viewVasts = (e) => {
        setId(e.target.id);
        setNewDemand(false);
        setDemandVast(true);
        setOpen(true);
    }

    const addDemand = () => {
        setDemandVast(false);
        setNewDemand(true);
        setOpen(true);
    }

    const handleClose = () => {
        setDemandVast(false);
        setNewDemand(false);
        setOpen(false);
    };

    return (
        <div className={styles.wrapper} >
            <button onClick={addDemand} className={styles.addDemand}>New Demand</button>
            {loading ? <LinearProgress /> :
                <table className={styles.table}>
                    <thead className={styles.tableRowHeader}>
                        <tr>
                            <th className={styles.tableRowHeader1}>ID</th>
                            <th>Name</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                            <th className={styles.tableRowHeader2}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {demand.map((demand) => {
                            return (<tr key={demand.id}>
                                <td>{demand.id}</td>
                                <td>{demand.name}</td>
                                <td>{demand.createdAt}</td>
                                <td>{demand.updatedAt}</td>
                                <td><button id={demand.id} onClick={viewVasts} className={styles.addVast}>view Vast</button></td>
                            </tr>
                            )
                        })}
                    </tbody>
                </table>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{ style: { background: "rgba(0, 0, 0, 0.15)" } }}
            >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    {demandVast &&
                        <DemandVasts
                            id={id}
                            onClose={handleClose}
                        />
                    }
                    {newDemand &&
                        <NewDemand
                            onClose={handleClose}
                        />
                    }
                </DialogContent>
            </Dialog>
        </div>
    )

}

export default Demand