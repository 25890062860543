import Login from './Components/Login/Login'
import Layout from './Components/Layout/Layout'
import Publishers from './Components/Publishers/Publishers'
import Demand from './Components/Demand/Demand'
import Tags from './Components/Tags/Tags'
import Duplicator from './Components/PeakDuplicator/Duplicator'
import PrivateRoutes from './Components/PrivateRoutes/PrivateRoutes'
import { Routes, Route, Navigate } from 'react-router-dom'



function App() {

  return (
    <div className='App'>
      <Routes>

        {/* public routes */}
        <Route path="/" element={<Login />} exact />
        {/* <Route path="/" element={<Navigate to='/' replace />} exact /> */}


        {/* protected routes */}
        <Route element={<PrivateRoutes />}>
          <Route path="/admin/layout/" element={<Layout />}>
            <Route path="/admin/layout/tags" element={<Tags />} />
            <Route path="/admin/layout/publishers" element={<Publishers />} exact />
            <Route path="/admin/layout/demand" element={<Demand />} />
            <Route path="/admin/layout/duplicator" element={<Duplicator />} />
          </Route>

          {/* invalid link */}
          <Route path='*' element={<p>Page Not Found!</p>} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
