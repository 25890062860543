import { useEffect, useState } from 'react'
import styles from './Tags.module.css'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ViewTag from '../ViewTag/ViewTag';
import Vasts from '../Vasts/Vasts';
import EventCreator from '../EventCreator/EventCreator';
import CallbacksTable from '../CallbacksTable/CallbacksTable'
import TagCreator from '../TagCreator/TagCreator';
import { useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';




const Tags = () => {

    const navigate = useNavigate()

    const [tags, setTags] = useState([]);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [showTag, setShowTag] = useState(false);
    const [showVast, setShowVast] = useState(false);
    const [showEvent, setShowEvent] = useState(false);
    const [showCallback, setShowCallback] = useState(false);
    const [showCreateTag, setShowCreateTag] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        (async () => {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/tags`, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            });
            if (res.status === 403) {
                navigate("/");
            }
            const data = await res.json();
            if (data) {
                setLoading(false)
                setTags(data);
            }
        }
        )()

    }, [open])


    const createTag = () => {
        setShowCreateTag(true)
        setOpen(true);
    }


    const viewTag = (e) => {
        setId(e.target.id);
        setName(e.target.name);
        setShowTag(true);
        setOpen(true);
    };

    const getVasts = (e) => {
        setId(e.target.id);
        setShowVast(true)
        setOpen(true);

    }
    const addEvent = (e) => {
        setId(e.target.id);
        setShowEvent(true);
        setOpen(true);

    }

    const addCallback = (e) => {
        setId(e.target.id);
        setShowCallback(true)
        setOpen(true);
    }


    const handleClose = () => {
        setOpen(false);
        setShowTag(false)
        setShowVast(false)
        setShowEvent(false)
        setShowCallback(false)
        setShowCreateTag(false)
    };


    return (
        <div>
            <button onClick={createTag} className={styles.addTag}>New Tag</button>
            {loading ? <LinearProgress /> :
                <table className={styles.table}>
                    <thead className={styles.tableRowHeader}>
                        <tr>
                            <th className={styles.tableRowHeader1}>Tag ID</th>
                            <th>Advertiser ID</th>
                            <th>Publisher Name</th>
                            <th>Description</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th className={styles.tableRowHeader2}></th>

                        </tr>
                    </thead>
                    <tbody>
                        {tags.map((tag) => {
                            return (<tr key={tag.id}>
                                <td>{tag.id}</td>
                                <td>{tag.publisher_id}</td>
                                <td>{tag.name}</td>
                                <td>{tag.description}</td>
                                <td>{tag.createdAt}</td>
                                <td>{tag.updatedAt}</td>
                                <td><button className={styles.bu} id={tag.id} name={tag.name} variant="outlined" onClick={viewTag}>Tag</button></td>
                                <td><button id={tag.id} onClick={getVasts}>Vasts</button></td>
                                <td><button id={tag.id} onClick={addEvent}>+ Add Event</button></td>
                                <td><button name={tag.name} id={tag.id} onClick={addCallback} className={styles.addVast}>Add Callback</button></td>
                            </tr>
                            )
                        })}
                    </tbody>
                </table>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{ style: { background: "rgba(0, 0, 0, 0.15)" } }}
            >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    {showTag &&
                        <ViewTag id={id}
                            name={name}
                            onClose={handleClose}
                        />
                    }
                    {showVast &&
                        <Vasts
                            id={id}
                            onClose={handleClose}
                        />
                    }
                    {showEvent &&
                        <EventCreator
                            id={id}
                            onClose={handleClose}
                        />
                    }
                    {showCallback &&
                        <CallbacksTable
                            id={id}
                            name={name}
                            onClose={handleClose}
                        />
                    }
                    {showCreateTag &&
                        <TagCreator
                            onClose={handleClose}
                        />
                    }
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Tags;